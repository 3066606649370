import {FrameworkVersionId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["tprm", "frameworkVersion", param<FrameworkVersionId>("frameworkVersionId")], async context => {
    return await api.tprm.frameworkVersions.get(context.queryKey[2]);
  }),
  typedQuery(["tprm", "frameworkVersionProfiles", param<FrameworkVersionId>("frameworkVersionId")], async context => {
    return await api.tprm.frameworkVersions.listProfiles(context.queryKey[2]);
  }),
];
