import api from "..";
import {FrameworkType, FrameworkVersionMin, FrameworkVersionId, FrameworkProfileMin} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function get(frameworkVersionId: FrameworkVersionId): Promise<FrameworkVersionMin> {
  return jsonApi.get(`/tprm/framework_versions/${frameworkVersionId}`);
}

export async function listProfiles(frameworkVersionId: FrameworkVersionId): Promise<FrameworkProfileMin[]> {
  return jsonApi.get(`/tprm/framework_versions/${frameworkVersionId}/profiles`);
}

export async function delete_(frameworkVersionId: FrameworkVersionId): Promise<string> {
  const res = await jsonApi.delete_<"ok">(`/tprm/framework_versions/${frameworkVersionId}`);
  await invalidateQueries([{queryKey: ["tprm", "frameworks"]}]);
  await invalidateQueries([{queryKey: ["tprm", "frameworkVersions"]}]);
  return res;
}

export async function updateVersion(frameworkVersionId: FrameworkVersionId, version: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/framework_versions/${frameworkVersionId}/version`, version);
  await invalidateQueries([{queryKey: ["tprm", "frameworks"]}]);
  await invalidateQueries([{queryKey: ["tprm", "frameworkVersions"]}]);
  return res;
}

/**
 * Temporary helper to make generating the hardcoded demo data easier.
 * Lists the first framework version for each framework.
 */
export async function debugList(): Promise<FrameworkVersionMin[]> {
  const frameworks = await api.tprm.frameworks.list();

  // Fetch a full framework version for each framework
  const frameworkVersions = await Promise.all(
    frameworks.map((f, i) =>
      api.tprm.frameworks
        .listVersions(f.framework_id)
        .then(a => api.tprm.frameworkVersions.get(a[0].framework_version_id))
        .then(fv => ({
          ...fv,
          framework: {...fv.framework, type: i === 1 ? FrameworkType.ProcurementFramework : FrameworkType.Standard},
        })),
    ),
  );

  return frameworkVersions;
}
