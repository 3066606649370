import jsonApi from "./jsonApi";
import {invalidateQueries} from "../state";
import {AccountFeature, AiModelConfig, PromptModifiers} from "../Types.ts";

export async function changeName(name: string) {
  const res = await jsonApi.post<"ok">("/config/account_name", name);
  await invalidateQueries([{queryKey: ["whoAmI"]}, {queryKey: ["accounts"]}]);
  return res;
}

export async function updateLanguageCode(languageCode: string) {
  const res = await jsonApi.post<"ok">("/config/language_code", languageCode);
  await invalidateQueries([{queryKey: ["whoAmI"]}, {queryKey: ["accounts"]}]);
  return res;
}

export async function updatePlanFeature(feature: AccountFeature, active: boolean) {
  const res = await jsonApi.post<"ok">(`/config/plan_features/${feature}`, active);
  await invalidateQueries([{queryKey: ["whoAmI"]}, {queryKey: ["accounts"]}]);
  return res;
}

export async function updateEnabledFeature(feature: AccountFeature, enabled: boolean) {
  const res = await jsonApi.post<"ok">(`/config/enabled_features/${feature}`, enabled);
  await invalidateQueries([{queryKey: ["whoAmI"]}, {queryKey: ["accounts"]}]);
  return res;
}

export async function updatePromptModifiers(preferences: PromptModifiers) {
  const res = await jsonApi.post<"ok">("/config/prompt_modifiers", preferences);
  await invalidateQueries([{queryKey: ["whoAmI"]}, {queryKey: ["accounts"]}]);
  return res;
}

export async function updateAiModelConfig(config: AiModelConfig) {
  const res = jsonApi.post<"ok">("/config/ai_model_config", config);
  await invalidateQueries([{queryKey: ["whoAmI"]}, {queryKey: ["accounts"]}]);
  return res;
}
