import {
  AccountId,
  AssetId,
  ContentId,
  DocumentAccessRequest,
  DocumentExternal,
  DocumentId,
  GetGraphQuery,
  GraphProperty,
  MailSubscriptionId,
  SARRequest,
  SubGraph,
  SubscribeRequest,
  SubscriptionAction,
  TrustCenterConfigMin,
  TrustCenterContent,
  TrustCenterFaq,
} from "../../Types";
import jsonApi, {API_BASE} from "./jsonApi";
import {invalidateQueries} from "../../state";
import {getTrustCenterPassword} from "../../TrustCenter/hooks/accessPassword";

function basicAuth(accountId: AccountId): {Authorization: string} {
  const password = getTrustCenterPassword(accountId);
  const token = btoa(`${accountId}:${password}`);
  return {Authorization: `Basic ${token}`};
}

function basicAuthUrl(accountId: AccountId, url: string): string {
  const urlObj = new URL(url);
  const auth = basicAuth(accountId).Authorization;
  if (auth) {
    urlObj.searchParams.append("auth", auth);
  }
  return urlObj.toString();
}

export async function listDocuments(accountId: AccountId) {
  return await jsonApi.get<DocumentExternal[]>(`/trust_centers/${accountId}/documents`, {
    headers: basicAuth(accountId),
  });
}

export const downloadDocument = async (accountId: AccountId, documentId: DocumentId) => {
  const a = document.createElement("a");
  a.href = basicAuthUrl(accountId, `${API_BASE}/trust_centers/${accountId}/documents/${documentId}/download`);
  a.click();
};

export async function requestDocumentAccess(
  accountId: AccountId,
  documentId: DocumentId,
  documentRequest: DocumentAccessRequest,
) {
  return await jsonApi.post<"ok">(
    `/trust_centers/${accountId}/documents/${documentId}/request_access`,
    documentRequest,
    {
      headers: basicAuth(accountId),
    },
  );
}

export const getAssetUrl = (accountId: AccountId, assetId: AssetId) => {
  return basicAuthUrl(accountId, `${API_BASE}/trust_centers/${accountId}/assets/${assetId}/download`);
};

export const getContent = async (accountId: AccountId, contentId: ContentId) => {
  return await jsonApi.get<TrustCenterContent>(`/trust_centers/${accountId}/contents/${contentId}`, {
    headers: basicAuth(accountId),
  });
};

export const getConfig = async (accountId: AccountId) => {
  return await jsonApi.get<TrustCenterConfigMin>(`/trust_centers/${accountId}/config`, {
    headers: basicAuth(accountId),
  });
};

export const getFaq = async (accountId: AccountId) => {
  return await jsonApi.get<TrustCenterFaq>(`/trust_centers/${accountId}/faq`, {
    headers: basicAuth(accountId),
  });
};

export const getAiFaq = async (accountId: AccountId) => {
  return await jsonApi.get<TrustCenterFaq>(`/trust_centers/${accountId}/ai_faq`, {
    headers: basicAuth(accountId),
  });
};

export const getGraph = async (accountId: AccountId, query: GetGraphQuery) => {
  return await jsonApi.get<SubGraph>(`/trust_centers/${accountId}/graph?${new URLSearchParams(query as any)}`, {
    headers: basicAuth(accountId),
  });
};

export const getSchema = async (accountId: AccountId) => {
  return await jsonApi.get<{[k: string]: GraphProperty}>(`/trust_centers/${accountId}/schema`, {
    headers: basicAuth(accountId),
  });
};

export async function subscribe(accountId: AccountId, subscribeRequest: SubscribeRequest) {
  const res = await jsonApi.post<SubscriptionAction>(`/trust_centers/${accountId}/subscribe`, subscribeRequest, {
    headers: basicAuth(accountId),
  });
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterMailingList"]}]);
  return res;
}

export async function verifySubscriptionEmail(mailSubscriptionId: MailSubscriptionId, token: string) {
  return await jsonApi.post<"ok">(
    `/mail_subscription/${mailSubscriptionId}/verify`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function authenticateTrustCenterPassword(accountId: AccountId, password: string) {
  return await jsonApi.post<"ok">(`/trust_centers/${accountId}/password?${new URLSearchParams({password})}`);
}

export async function sarRequest(accountId: AccountId, sarRequest: SARRequest) {
  return await jsonApi.post<"ok">(`/trust_centers/${accountId}/sar`, sarRequest, {
    headers: basicAuth(accountId),
  });
}
