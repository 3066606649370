import {
  Assessment,
  AssessmentId,
  AssessmentMin,
  AssessmentStatus,
  FrameworkProfile,
  ThirdPartyId,
  ThirdPartyMin,
  nominate,
} from "../../Types";
import {whoAmI} from "../auth";
import api from "..";
import {addDays, formatISO} from "date-fns";

const days_ago = (days: number) => nominate("datetime", formatISO(addDays(new Date(), -days)));
const TODAY = nominate("datetime", formatISO(new Date()));
const YESTERDAY = days_ago(1);

export async function generateDemoAssessments(
  thirdParties: ThirdPartyMin[],
  frameworkProfiles: FrameworkProfile[],
): Promise<Assessment[]> {
  // Generate controls map for each framework version
  const controls = frameworkProfiles.map(fv =>
    fv.control_groups.flatMap(cg => cg.controls).reduce((acc, c) => ({...acc, [c.control_id]: c}), {}),
  );

  return [
    {
      name: "Premium 2024",
      assessment_id: nominate("assessmentId", "Assessment1"),
      third_party: thirdParties[0],
      framework_version: frameworkProfiles[2].framework_version,
      unmitigated_risks: 0,
      status: AssessmentStatus.Conforms,
      control_count: 122,
      controls: controls[2],
      created_at: days_ago(8),
      updated_at: TODAY,
    },
    {
      name: "Light 2023",
      assessment_id: nominate("assessmentId", "Assessment2"),
      third_party: thirdParties[0],
      framework_version: frameworkProfiles[0].framework_version,
      control_count: 8,
      unmitigated_risks: 3,
      status: AssessmentStatus.NonConformities,
      controls: controls[0],
      created_at: days_ago(373),
      updated_at: days_ago(340),
    },
    {
      name: "Light 2024",
      assessment_id: nominate("assessmentId", "Assessment3"),
      third_party: thirdParties[0],
      framework_version: frameworkProfiles[0].framework_version,
      control_count: 8,
      unmitigated_risks: 3,
      status: AssessmentStatus.NonConformities,
      controls: controls[0],
      created_at: YESTERDAY,
      updated_at: YESTERDAY,
    },
    {
      name: "FedRamp",
      assessment_id: nominate("assessmentId", "Assessment4"),
      third_party: thirdParties[0],
      framework_version: frameworkProfiles[1].framework_version,
      control_count: 5,
      unmitigated_risks: 3,
      status: AssessmentStatus.NonConformities,
      controls: controls[1],
      created_at: YESTERDAY,
      updated_at: YESTERDAY,
    },
  ];
}

export async function list(thirdPartyId: ThirdPartyId): Promise<AssessmentMin[]> {
  const whoami = await whoAmI();

  const thirdParties = await api.tprm.thirdParties.list();
  const frameworkProfiles = await api.tprm.frameworkProfiles.debugList();

  return (await generateDemoAssessments(thirdParties, frameworkProfiles))
    .filter(a => a.third_party.third_party_id == thirdPartyId)
    .map(a => ({
      owner: whoami.user_owner,
      ...a,
    }));
}

export async function get(assessmentId: AssessmentId): Promise<Assessment> {
  const whoami = await whoAmI();

  const thirdParties = await api.tprm.thirdParties.list();
  const frameworkProfiles = await api.tprm.frameworkProfiles.debugList();

  const a = (await generateDemoAssessments(thirdParties, frameworkProfiles)).find(
    a => a.assessment_id == assessmentId,
  )!;

  a.owner = whoami.user_owner;
  return a;
}
