import thirdParties from "./thirdParties.ts";
import thirdPartyTiers from "./thirdPartyTiers.ts";
import thirdPartyStatuses from "./thirdPartyStatuses.ts";
import thirdPartyTags from "./thirdPartyTags.ts";
import frameworks from "./frameworks.ts";
import frameworkVersions from "./frameworkVersions.ts";
import frameworkProfiles from "./frameworkProfiles.ts";
import assessments from "./assessments.ts";
import risks from "./risks.ts";
import dataRooms from "./dataRooms";
import sources from "./sources";

export default [
  ...thirdParties,
  ...thirdPartyTiers,
  ...thirdPartyStatuses,
  ...thirdPartyTags,
  ...frameworks,
  ...frameworkVersions,
  ...frameworkProfiles,
  ...assessments,
  ...risks,
  ...dataRooms,
  ...sources,
];
