import {FileId, Source, SourceId} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function get(sourceId: SourceId): Promise<Source> {
  return jsonApi.get<Source>(`/tprm/sources/${sourceId}`);
}

export async function delete_(sourceId: SourceId): Promise<"ok"> {
  const res = await jsonApi.delete_<"ok">(`/tprm/sources/${sourceId}`);
  await invalidateQueries([{queryKey: ["tprm", "dataRoomSources"]}]);
  return res;
}

export async function updateDocumentFile(sourceId: SourceId, fileId: FileId): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/tprm/sources/${sourceId}/file`, fileId);
  await invalidateQueries([{queryKey: ["tprm", "source", sourceId]}, {queryKey: ["tprm", "dataRoomSources"]}]);
  return res;
}
