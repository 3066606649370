import React from "react";
import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import type {Integration as SentryIntegration} from "@sentry/types";
import {useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from "react-router-dom";
import {fullStoryIntegration} from "@sentry/fullstory";
import Plausible from "plausible-tracker";

import {Account, RegisteredUser, Environment, UserId, AccountId} from "../Types";
import {getFrontendEnvironment, getPublicCredentials} from "./environment";
import {LogType} from "platformed-browser-api/platformed";
import {extensionClient} from "../Extension/provider";
import {Json} from "platformed-browser-api/protocol";

let identifiedUserId: UserId | null = null;
let identifiedAccountId: AccountId | null = null;
let plausibleProps: {readonly [propName: string]: string | number | boolean} = {};
const enableFullStory = !localStorage.getItem("disableFullStory");

function sanitizeLogArg(arg: any): Json {
  try {
    if (arg instanceof Error) {
      return arg.toString();
    }
    return JSON.parse(JSON.stringify(arg));
  } catch {
    return `${arg}`;
  }
}

declare global {
  interface Window {
    SentryClient: ReturnType<typeof Sentry.init>;
  }
}

export function analyticsInit() {
  try {
    const {environment, release} = getFrontendEnvironment();
    const {sentry_dsn, fullstory_org} = getPublicCredentials();

    // Forward log messages to parent window in case we are running in the extension
    // sidebar's iframe, which cannot otherwise be debugged in Firefox.
    if (environment === Environment.Development && window.parent !== window) {
      for (const logType of Object.values(LogType) as LogType[]) {
        const original = console[logType];
        console[logType] = (...args) => {
          original(...args);
          extensionClient.request("logMessage", logType, args.map(sanitizeLogArg));
        };
      }
      window.addEventListener("error", e => {
        if (!e.error?._isNominal) {
          extensionClient.request("logMessage", LogType.Error, [sanitizeLogArg(e)]);
        }
      });
      console.log("analyticsInit");
    }

    window.addEventListener("error", e => {
      if (e.error?._isNominal) {
        e.preventDefault();
      }
    });
    const originalError = console["error"];
    console["error"] = (...args) => {
      if (args[0] === "React Router caught the following error during render" && args[1]?._isNominal) {
        // Suppress stupid react errors
        return;
      } else if (args[0]?._isNominal) {
        return;
      }
      originalError(...args);
    };

    const {trackPageview} = Plausible({
      domain: window.location.hostname,
      apiHost: "/relay/plausible",
      trackLocalhost: true,
    });

    // Automatically track plausible pageviews
    const page = () => trackPageview(undefined, {props: {...plausibleProps}});
    // Attach pushState and popState listeners
    const originalPushState = history.pushState;
    if (originalPushState) {
      history.pushState = function (data, title, url) {
        originalPushState.apply(this, [data, title, url]);
        page();
      };
      addEventListener("popstate", page);
    }

    // Trigger first page view
    page();

    if (fullstory_org && enableFullStory) {
      const host = "fsrelay.platformed.com";
      const script = `${host}/s/fs.js`;
      FullStory.init({orgId: fullstory_org, script, host}, ({sessionUrl}) =>
        console.log(`Started FullStory session ${sessionUrl}`),
      );
    }

    if (sentry_dsn) {
      const integrations: SentryIntegration[] = [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.captureConsoleIntegration({levels: ["error"]}),
      ];
      if (fullstory_org) {
        integrations.push(fullStoryIntegration("platformed", {client: FullStory}));
      }
      window.SentryClient = Sentry.init({
        dsn: sentry_dsn,
        environment,
        release,
        integrations,
        tracesSampleRate: 1.0,
        beforeSend(event, hint) {
          // Don't report "nominal" exceptions
          const originalException = hint.originalException as {_isNominal?: boolean} | undefined;
          if (originalException?._isNominal) {
            return null;
          }
          // Prevent grouping errors across different environments
          event.fingerprint = ["{{ default }}", environment];
          return event;
        },
      });
    }
  } catch (ex) {
    // Don't interrupt whatever the caller was doing
    console.error(ex);
  }
}

export function analyticsIdentifyUser(user: RegisteredUser) {
  Sentry.setUser({id: user.user_id, email: user.primary_email, username: user.name});

  plausibleProps = {
    ...plausibleProps,
    isPlatformedUser: user.primary_email.endsWith("@platformed.com"),
    email: user.primary_email,
  };

  if (plausibleProps.isPlatformedUser) {
    // Disable FullStory tracking for anyone who has logged in as a Platformed user
    localStorage.setItem("disableFullStory", "true");
  }

  if (enableFullStory) {
    try {
      const {fullstory_org} = getPublicCredentials();
      if (identifiedUserId !== user.user_id && fullstory_org) {
        identifiedAccountId = null;
        identifiedUserId = user.user_id;
        FullStory.identify(user.user_id, {
          displayName: user.name,
          email: user.primary_email,
        });
      }
    } catch (ex) {
      // Don't interrupt whatever the caller was doing
      console.error(ex);
    }
  }
}

export function analyticsIdentifyAccount(account: Account) {
  Sentry.setTags({
    "account.id": account.account_id,
    "account.name": account.display_name,
    "account.type": account.type_,
  });

  plausibleProps = {
    ...plausibleProps,
    accountName: account.name,
  };
  try {
    const {fullstory_org} = getPublicCredentials();
    if (identifiedAccountId !== account.account_id && fullstory_org && enableFullStory) {
      identifiedAccountId = account.account_id;
      FullStory.event("Account", {
        account: {
          id_str: account.account_id,
          name_str: account.name,
        },
      });
    }
  } catch (ex) {
    // Don't interrupt whatever the caller was doing
    console.error(ex);
  }
}
