import {invalidateQueries} from "../../state";
import {CreateThirdPartyTier, ThirdPartyTierId, ThirdPartyTierMin} from "../../Types";
import jsonApi from "../jsonApi";

export async function list(): Promise<ThirdPartyTierMin[]> {
  return await jsonApi.get<ThirdPartyTierMin[]>("/tprm/third_party_tiers");
}

export async function create(tier: CreateThirdPartyTier) {
  const res = await jsonApi.post<"ok">("/tprm/third_party_tiers", tier);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTiers"]}]);
  return res;
}

export async function updateTitle(tierId: ThirdPartyTierId, title: string) {
  const res = await jsonApi.post<"ok">(`/tprm/third_party_tiers/${tierId}/title`, title);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTiers"]}]);
  return res;
}

export async function updateColor(tierId: ThirdPartyTierId, color: string) {
  const res = await jsonApi.post<"ok">(`/tprm/third_party_tiers/${tierId}/color`, color);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTiers"]}]);
  return res;
}

export async function reorder(tierId: ThirdPartyTierId, ordinal: number) {
  const res = await jsonApi.post<"ok">(`/tprm/third_party_tiers/${tierId}/ordinal`, ordinal);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTiers"]}]);
  return res;
}

export async function delete_(tierId: ThirdPartyTierId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/third_party_tiers/${tierId}`);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTiers"]}]);
  return res;
}
