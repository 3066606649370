import {
  CreateDataRoom,
  CreateThirdParty,
  DataRoomMin,
  OwnerId,
  ThirdParty,
  ThirdPartyId,
  ThirdPartyMin,
  ThirdPartyTagId,
  ThirdPartyTierId,
} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function list(): Promise<ThirdPartyMin[]> {
  return await jsonApi.get<ThirdPartyMin[]>("/tprm/third_parties");
}

export async function get(thirdPartyId: ThirdPartyId): Promise<ThirdParty> {
  return await jsonApi.get<ThirdParty>(`/tprm/third_parties/${thirdPartyId}`);
}

export async function create(thirdParty: CreateThirdParty): Promise<ThirdParty> {
  const res = await jsonApi.post<ThirdParty>("/tprm/third_parties", thirdParty);
  await invalidateQueries([{queryKey: ["tprm", "thirdParties"]}]);
  return res;
}

export async function delete_(thirdPartyId: ThirdPartyId): Promise<string> {
  const res = await jsonApi.delete_<"ok">(`/tprm/third_parties/${thirdPartyId}`);
  await invalidateQueries([{queryKey: ["tprm", "thirdParties"]}]);
  return res;
}

export async function updateName(thirdPartyId: ThirdPartyId, name: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/name`, name);
  await invalidateQueries([{queryKey: ["tprm", "thirdParty", thirdPartyId]}]);
  return res;
}

export async function updateUrl(thirdPartyId: ThirdPartyId, url: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/url`, url);
  await invalidateQueries([{queryKey: ["tprm", "thirdParty", thirdPartyId]}]);
  return res;
}

export async function updateTier(thirdPartyId: ThirdPartyId, tierId: ThirdPartyTierId): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/tier_id`, tierId);
  await invalidateQueries([{queryKey: ["tprm", "thirdParty", thirdPartyId]}]);
  return res;
}

export async function updateTags(thirdPartyId: ThirdPartyId, tagIds: ThirdPartyTagId[]): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/tag_ids`, tagIds);
  await invalidateQueries([{queryKey: ["tprm", "thirdParty", thirdPartyId]}]);
  return res;
}

export async function updateBusinessOwner(thirdPartyId: ThirdPartyId, ownerId: OwnerId | null): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/business_owner_id`, ownerId);
  await invalidateQueries([{queryKey: ["tprm", "thirdParty", thirdPartyId]}]);
  return res;
}

export async function updateRiskOwner(thirdPartyId: ThirdPartyId, ownerId: OwnerId | null): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/risk_owner_id`, ownerId);
  await invalidateQueries([{queryKey: ["tprm", "thirdParty", thirdPartyId]}]);
  return res;
}

export async function listDataRooms(thirdPartyId: ThirdPartyId): Promise<DataRoomMin[]> {
  return await jsonApi.get<DataRoomMin[]>(`/tprm/third_parties/${thirdPartyId}/data_rooms`);
}

export async function createDataRoom(thirdPartyId: ThirdPartyId, dataRoom: CreateDataRoom): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/data_rooms`, dataRoom);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyDataRooms", thirdPartyId]}]);
  return res;
}
