import {DataRoomId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["tprm", "dataRoom", param<DataRoomId>("dataRoomId")], async context => {
    return await api.tprm.dataRooms.get(context.queryKey[2]);
  }),
  typedQuery(["tprm", "dataRoomSources", param<DataRoomId>("dataRoomId")], async context => {
    return await api.tprm.dataRooms.listSources(context.queryKey[2]);
  }),
];
