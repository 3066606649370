import auth from "./auth";
import loginMethod from "./loginMethod";
import user from "./user";
import group from "./group";
import selfEnrollmentDomain from "./selfEnrollmentDomain";
import knock from "./knock";
import backgroundTask from "./backgroundTask";
import notificationChannel from "./notificationChannel.ts";
import notificationPreferences from "./notificationPreferences.ts";
import external from "./external";
import team from "./team";
import owner from "./owner";
import reportedError from "./reportedError.ts";
import vendorToolkit from "./vendorToolkit";
import tprm from "./tprm";

export default [
  ...auth,
  ...loginMethod,
  ...user,
  ...group,
  ...selfEnrollmentDomain,
  ...knock,
  ...backgroundTask,
  ...notificationChannel,
  ...notificationPreferences,
  ...external,
  ...team,
  ...owner,
  ...reportedError,
  ...vendorToolkit,
  ...tprm,
];
