import * as thirdParties from "./thirdparties";
import * as frameworks from "./frameworks";
import * as frameworkVersions from "./frameworkVersions";
import * as frameworkProfiles from "./frameworkProfiles";
import * as assessments from "./assessments";
import * as risks from "./risks";
import * as thirdPartyTiers from "./thirdPartyTiers";
import * as thirdPartyStatuses from "./thirdPartyStatuses";
import * as thirdPartyTags from "./thirdPartyTags";
import * as dataRooms from "./dataRooms";
import * as sources from "./sources";

const tprm = {
  thirdParties,
  thirdPartyTiers,
  thirdPartyStatuses,
  thirdPartyTags,
  frameworks,
  frameworkVersions,
  frameworkProfiles,
  assessments,
  risks,
  dataRooms,
  sources,
};

export default tprm;
