import api from "..";
import {FrameworkProfileId, FrameworkProfile, FrameworkType} from "../../Types";
import jsonApi from "../jsonApi";

export async function get(frameworkProfileId: FrameworkProfileId): Promise<FrameworkProfile> {
  return jsonApi.get(`/tprm/framework_profiles/${frameworkProfileId}`);
}

export async function debugList(): Promise<FrameworkProfile[]> {
  const frameworks = await api.tprm.frameworks.list();

  // Fetch a full framework version for each framework
  const frameworkProfiles = await Promise.all(
    frameworks.map(async (f, i) => {
      const [version] = await api.tprm.frameworks.listVersions(f.framework_id);
      const [profileMin] = await api.tprm.frameworkVersions.listProfiles(version.framework_version_id);
      const profile = await get(profileMin.framework_profile_id);
      return {
        ...profile,
        framework_version: {
          ...profile.framework_version,
          framework: {
            ...profile.framework_version.framework,
            type: i === 1 ? FrameworkType.ProcurementFramework : FrameworkType.Standard,
          },
        },
      };
    }),
  );

  return frameworkProfiles;
}
