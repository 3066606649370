import {NotificationPreferencesConfig, UpdateNotificationPreference} from "../Types.ts";
import jsonApi from "./jsonApi.ts";
import {invalidateQueries} from "../state";

export async function get_(): Promise<NotificationPreferencesConfig> {
  return await jsonApi.get<NotificationPreferencesConfig>(`/notification_preferences`);
}

export async function create() {
  const res = await jsonApi.post<"ok">(`/notification_preferences`);
  await invalidateQueries([{queryKey: ["notificationPreferences"]}]);
  return res;
}

export async function update(preferenceConfig: UpdateNotificationPreference) {
  const res = await jsonApi.post<"ok">(`/notification_preferences/update`, preferenceConfig);
  await invalidateQueries([{queryKey: ["notificationPreferences"]}]);
  return res;
}
