import {FrameworkMin, FrameworkId, FrameworkVersionMin, CreateFrameworkVersion, CreateFramework} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function list(): Promise<FrameworkMin[]> {
  return jsonApi.get("/tprm/frameworks");
}

export async function get(frameworkId: FrameworkId): Promise<FrameworkMin> {
  return jsonApi.get(`/tprm/frameworks/${frameworkId}`);
}

export async function create(framework: CreateFramework): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks`, framework);
  await invalidateQueries([{queryKey: ["tprm", "frameworks"]}]);
  return res;
}

export async function delete_(frameworkId: FrameworkId): Promise<string> {
  const res = await jsonApi.delete_<"ok">(`/tprm/frameworks/${frameworkId}`);
  await invalidateQueries([{queryKey: ["tprm", "frameworks"]}]);
  await invalidateQueries([{queryKey: ["tprm", "frameworkVersions"]}]);
  return res;
}

export async function createVersion(frameworkId: FrameworkId, version: CreateFrameworkVersion): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks/${frameworkId}/versions`, version);
  await invalidateQueries([{queryKey: ["tprm", "framework", frameworkId]}]);
  await invalidateQueries([{queryKey: ["tprm", "frameworkVersions"]}]);
  return res;
}

export async function listVersions(frameworkId: FrameworkId): Promise<FrameworkVersionMin[]> {
  return jsonApi.get(`/tprm/frameworks/${frameworkId}/versions`);
}

export async function updateName(frameworkId: FrameworkId, name: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks/${frameworkId}/name`, name);
  await invalidateQueries([{queryKey: ["tprm", "framework", frameworkId]}]);
  return res;
}
