import {CreateSource, DataRoomId, DataRoomMin, Source, SourceMin} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function get(dataRoomId: DataRoomId): Promise<DataRoomMin> {
  return jsonApi.get<DataRoomMin>(`/tprm/data_rooms/${dataRoomId}`);
}

export async function createSource(dataRoomId: DataRoomId, source: CreateSource): Promise<Source> {
  const res = await jsonApi.post<Source>(`/tprm/data_rooms/${dataRoomId}/sources`, source);
  await invalidateQueries([{queryKey: ["tprm", "dataRoomSources", dataRoomId]}]);
  return res;
}

export async function listSources(dataRoomId: DataRoomId): Promise<SourceMin[]> {
  return jsonApi.get<SourceMin[]>(`/tprm/data_rooms/${dataRoomId}/sources`);
}
