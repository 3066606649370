import {AssessmentId, ThirdPartyId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["tprm", "thirdPartyRisks", param<ThirdPartyId>("thirdPartyId")], async context => {
    return await api.tprm.risks.listByThirdParty(context.queryKey[2]);
  }),
  typedQuery(["tprm", "assessmentRisks", param<AssessmentId>("assessmentId")], async context => {
    return await api.tprm.risks.listByAssessment(context.queryKey[2]);
  }),
];
